import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Import axios
import './withdrawalHistory.css';
import Header from '../navbar/header';

function TransactionHistory() {
    const [transactions, setTransactions] = useState([]); // State to store transactions
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [totalIncome, setTotalIncome] = useState(0);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [loading, setLoading] = useState(true); // State to manage loading
    const [error, setError] = useState(null); // State to manage errors

    const calculateIncome = useCallback((transactionsData) => {
        // Calculate total income
        const total = transactionsData.reduce((total, transaction) => total + transaction.amount, 0);
        setTotalIncome(total);

        // Calculate monthly income
        const month = selectedMonth;
        const monthly = transactionsData
            .filter(transaction => new Date(transaction.request_date).getMonth() === month)
            .reduce((total, transaction) => total + transaction.amount, 0);
        setMonthlyIncome(monthly);
    }, [selectedMonth]);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('Authentication token not found. Please log in.');
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_PROTOCOL}/api/withdrawal/user-withdrawal-history`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                
                if (response.data && response.data.data) {
                    const transactionsData = response.data.data;
                    setTransactions(transactionsData);
                    calculateIncome(transactionsData);
                } else {
                    setError('No transactions found.');
                }
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setError('Failed to fetch transactions. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [calculateIncome]);

    useEffect(() => {
        calculateIncome(transactions);
    }, [selectedMonth, transactions, calculateIncome]);

    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value, 10));
    };

    if (loading) {
        return <p>Loading transactions...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <Header/>
            <div className="withdrawal-history">
                <h2>User Withdrawal History</h2>
                <div className="income-summary">
                    <div className="income-item">
                        <h3>Total Income</h3>
                        <p>₹{Math.abs(totalIncome)}</p>
                    </div>
                    <div className="income-item">
                        <h3>Month Income</h3>
                        <p>₹{Math.abs(monthlyIncome)}</p>
                    </div>
                </div>
                <div className="month-selector">
                    <label htmlFor="month">Select Month: </label>
                    <select id="month" onChange={handleMonthChange} value={selectedMonth}>
                        {[...Array(12).keys()].map(month => (
                            <option key={month} value={month}>
                                {new Date(0, month).toLocaleString('default', { month: 'long' })}
                            </option>
                        ))}
                    </select>
                </div>
                <table className="withdrawal-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.length > 0 ? (
                            transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{new Date(transaction.request_date).toLocaleDateString()}</td>
                                    <td className={`withdrawal-amount ${transaction.amount < 0 ? 'amount-negative' : 'amount-positive'}`}>
                                        {`₹${Math.abs(transaction.amount)}`}
                                    </td>
                                    <td className={`withdrawal-status ${transaction.status.toLowerCase()}`}>{transaction.status}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="no-withdrawals">No withdrawals available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TransactionHistory;
