import React, { useState } from 'react';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,  // Import the Filler plugin
} from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './marketingPlans.css'; // Enhanced styles

// Register the necessary chart modules including the Filler plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const MarketingPlans = () => {
  const [isHindi, setIsHindi] = useState(false);

  const toggleLanguage = () => {
    setIsHindi(!isHindi);
  };

  const data = {
    labels: ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'],
    datasets: [
      {
        label: 'Earnings Growth (in ₹)',
        data: [12700, 19050, 25400, 50000, 100000],
        fill: false,
        backgroundColor: '#4caf50',
        borderColor: '#4caf50',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        onClick: true,
      },
      title: {
        display: true,
        text: 'Earnings Growth Over Levels',
      },
    },
    scales: {
      x: {
        ticks: { font: { size: 12 } },
      },
      y: {
        ticks: {
          font: { size: 12 },
          callback: function (value) {
            return value; // Show raw values
          },
        },
        min: 0, // Set the minimum value of the Y-axis
        max: 100000, // Set the maximum value of the Y-axis
        stepSize: 20000, // Set the interval size for ticks
      },
    },
  };
  return (
    <Container className="enhanced-marketing-container">
      {/* Animated Header Section */}
      <Row className="text-center marketing-header animate_animated animate_fadeInDown">
        <h1 className="enhanced-marketing-title">Empower Your Growth</h1>
        <p className="enhanced-marketing-subtitle">
          Join our program and unlock your earning potential. Climb higher with every level you conquer.
        </p>
      </Row>

      {/* Income Structure Section */}
      <Row className="mt-5 animate_animated animate_fadeInUp">
        <Col md={12}>
          <Card className="income-structure-card">
            <Card.Body>
              <Card.Title className="card-title">Income Structure</Card.Title>
              <Table className="enhanced-income-table">
                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Direct Sales Earnings</th>
                    <th>Total Earnings</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Level 1</td>
                    <td>₹100</td>
                    <td>₹12,700</td>
                  </tr>
                  <tr>
                    <td>Level 2</td>
                    <td>₹150</td>
                    <td>₹19,050</td>
                  </tr>
                  <tr>
                    <td>Level 3</td>
                    <td>₹200</td>
                    <td>₹25,400</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Program Details Section */}
      <Row className="mt-5 animate_animated animate_fadeInLeft">
        <Col md={12}>
          <Card className="program-details-card">
            <Card.Body>
              <Card.Title className="card-title">
                {isHindi ? 'Earners कैसे काम करता है' : 'How Earners Works'}
              </Card.Title>
              <p className="program-details-text">
                {isHindi
                  ? 'Earners से आप दो तरीके से पैसे कमा सकते हैं:'
                  : 'Earners allows you to earn in two ways:'}
              </p>
              <ul className="enhanced-program-list">
                <li>
                  <span className="program-icon animate_animated animate_pulse">💸</span>
                  <b>{isHindi ? 'सीधी बिक्री:' : 'Direct Sales:'}</b>{' '}
                  {isHindi
                    ? 'हर प्रोडक्ट को बेचने पर आपको कमीशन मिलेगा।'
                    : 'Earn commission on each product you sell.'}
                </li>
                <li>
                  <span className="program-icon animate_animated animate_pulse">👥</span>
                  <b>{isHindi ? 'टीम बिक्री:' : 'Team Sales:'}</b>{' '}
                  {isHindi
                    ? 'जब आपकी टीम बिक्री करती है, तो आपको बोनस और कमीशन मिलेगा।'
                    : 'Earn bonuses and commissions when your team makes sales.'}
                </li>
              </ul>
              <Button
                className="toggle-language-button animate_animated animate_bounce"
                onClick={toggleLanguage}
              >
                {isHindi ? 'English' : 'हिंदी'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Earnings Growth Chart Section */}
      <Row className="mt-4">
        <Col md={12}>
          <Card className="shadow p-3">
            <Card.Body>
              <Card.Title>Earnings Growth Over Levels</Card.Title>
              <div style={{ position: 'relative', height: '30vh', width: '100%' }}>
                <Line data={data} options={options} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MarketingPlans;