import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal, Button, Carousel } from 'react-bootstrap';
import { FaUsers, FaShoppingCart, FaWallet, FaMedal } from 'react-icons/fa'; // Import FaMedal icon for the levels
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './HomePageInfoCard.css'; // Custom CSS for styling

const InfoCard = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [level, setLevel] = useState('silver'); // Default state to 'silver'
    const [showModal, setShowModal] = useState(false); // Modal visibility state

    // Fetch user profile data from localStorage
    useEffect(() => {
        const storedProfile = JSON.parse(localStorage.getItem('userProfile'));
        if (storedProfile && storedProfile.level) {
            const userLevel = storedProfile.level;
            if (userLevel === 1) {
                setLevel('silver');
            } else if (userLevel === 2) {
                setLevel('gold');
            } else if (userLevel === 3) {
                setLevel('platinum');
            }
        }
    }, []); // Run this effect once on component mount

    // Determine the icon color based on the level
    const getLevelColor = () => {
        switch (level.toLowerCase()) {
            case 'gold':
                return '#ffd700'; // Gold color
            case 'silver':
                return '#c0c0c0'; // Silver color
            case 'platinum':
                return '#e5e4e2'; // Platinum color
            default:
                return '#c0c0c0'; // Default to silver if the level is unknown
        }
    };

    // Handle modal open and close
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Card className="p-3 mb-4 info-card-unique" style={{ borderRadius: '10px', border: '1px solid #ddd' }}>
                <Card.Body>
                    <Row className="align-items-center">
                        <Col
                            xs={3}
                            className="d-flex justify-content-center"
                            onClick={() => navigate('/members-data')} // Navigate to the /members-data route
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="text-center">
                                <FaUsers size={40} color="#4caf50" />
                                <Card.Text className="mt-2">Members</Card.Text>
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            className="d-flex justify-content-center"
                            onClick={() => navigate('/order-history')} // Navigate to the /order-history route
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="text-center">
                                <FaShoppingCart size={40} color="#2196f3" />
                                <Card.Text className="mt-2">Orders</Card.Text>
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            className="d-flex justify-content-center"
                            onClick={() => navigate('/wallet')} // Navigate to the /wallet route
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="text-center">
                                <FaWallet size={40} color="#ff9800" />
                                <Card.Text className="mt-2">Wallet</Card.Text>
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            className="d-flex justify-content-center"
                            onClick={handleOpenModal} // Open modal on click
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="text-center">
                                <FaMedal size={40} color={getLevelColor()} /> {/* Set the icon color based on level */}
                                <Card.Text className="level-text-unique">{level.charAt(0).toUpperCase() + level.slice(1)}</Card.Text> {/* Display the current level */}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Modal for showing current and upcoming level information */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                className="premium-modal"
            >
                <Modal.Header closeButton className="premium-modal-header">
                    <Modal.Title className="premium-modal-title">
                        Your Current Level: {level.charAt(0).toUpperCase() + level.slice(1)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="premium-modal-body">
                    <div className="premium-level-info">
                        <p>
                            <strong className='premium-current-level'>Current Level:</strong> {level.charAt(0).toUpperCase() + level.slice(1)}
                        </p>
                        <p>
                            Enjoy exclusive rewards, discounts, and access to premium content at your current level.
                        </p>
                        <h5 className="premium-upcoming-title">Upcoming Levels</h5>
                        <Carousel className="premium-level-carousel" controls={false} indicators={true} interval={3000}>
                            <Carousel.Item>
                                <div className="carousel-level-card silver-level">
                                    <img src="./silver.jpg" className="carousel-level-image" />                                 
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-level-card gold-level">
                                    <img src='./gold.jpg' className='carousel-level-image' />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-level-card platinum-level">
                                    <img src='./platinum.jpg' className='carousel-level-image' />
                                </div>
                            </Carousel.Item>
                        </Carousel>
                        <Button
                            variant="link"
                            className="premium-read-more"
                            onClick={() => navigate('/plans')}
                        >
                            Learn More About Plans
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer className="premium-modal-footer">
                    <Button variant="secondary" onClick={handleCloseModal} className="premium-close-btn">
                        Close
                    </Button>
                </Modal.Footer>
                </Modal>
        </>
    );
};

export default InfoCard;