import React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Banner, Testimonials, Achievements } from './DashboardEnhancements';
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css';
import './DashboardEnhancements.css';
import HeaderDashboard from './Headerdashboard';
import Footer from './Footer';

function Dashboard() {
  return (
    <>
      <HeaderDashboard />
      <Container className="dashboard-container">
        {/* Company Logo Section */}
        <div className="text-center mb-4">
          <img src='./earnersWaveLogo.png' alt="Company Logo" className="company-logo" />
          <h1>Welcome to earnerswave.com</h1>
          <p>Your journey to premium quality starts here!</p>
        </div>

        {/* Banner Section */}
        <Banner />

        {/* Kit Section */}
        <div className="kit-details">
          <h2>Our Premium Kit</h2>
          <Row className="justify-content-center">
            {/* Upper Row */}
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./rcm_vyomini_jute_bad.jpg" />
                <Card.Body>
                  <Card.Title>Combo Kit</Card.Title>
                  <Card.Text>A perfect therapy for youthful, naturally radiant skin.
                    Moringa extract helps optimize a visibly refined texture of skin.
                    Moisturize, enhance and promote an impressively healthy skin.
                    Lightweight, quickly absorb & non sticky.
                    Brilliant combination of organic resources (Moringa, Milk & Honey) those are full of nutrients</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./moringa_face_scrub.jpg" />
                <Card.Body>
                  <Card.Title>Moringa Face Scrub</Card.Title>
                  <Card.Text>Key Features:
                    Scrubs away dirt and dullness due to pollution from the skin
                    Rich in antioxidants which protect the skin cells from radical damage
                    Offers extra care and helps to prevent premature aging of skin
                    Exfoliates dead skin
                    Keeps skin pores clean for better perspiration of the cells to release toxins
                    Carries an exotic fragrance.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./moringa_hair_oil.jpg" />
                <Card.Body>
                  <Card.Title>Moringa Hair Oil</Card.Title>
                  <Card.Text>Key Features:
                     Regain fuller and denser hair.
                     Stimulates hair papilla cells and reduce hair loss.
                     RootBioTec™ proved in a clinical study to significantly reduce hair loss by 31% after just two months of application.
                     Richness of Sunflower, Rice bran, Vitamin E and Moringa oil maintain scalp health and fortifies hair follicles by deep
                     conditioning.
                     Free from Parabens, mineral oil and dyes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {/* Lower Row */}
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./moringa_soap.jpg" />
                <Card.Body>
                  <Card.Title>Moringa Soap</Card.Title>
                  <Card.Text>Key Features:
                    Experience authenticity of organic Honey, Milk, and Moringa extracts
                    Provides relishing freshness with every bath
                    Purifies, nourishes, and deeply cleanses your skin
                    Anti-oxidant properties reduce the rashes and dryness of your skin
                    Pleasant fragrance and freshness to keep you fresh all day long
                    .</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./moringa_cream.jpg" />
                <Card.Body>
                  <Card.Title>Moringa Creme</Card.Title>
                  <Card.Text>Key Features:Time to reveal the authentic charm! Now, you can unleash real softness and beauty of your skin.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./moringa_body_lotion.jpg" />
                <Card.Body>
                  <Card.Title>Moringa Body Loation</Card.Title>
                  <Card.Text>Key Features: Enhance the softness and suppleness of your skin with this lightweight yet incredibly effective moringa lotion. It's skin healthy nutrients such as Vitamin And Vitamin C will leave your skin looking radiant, smooth and silky to touch.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>         
        </div>

        {/* MLM Overview Section */}
        <div className="mlm-overview text-center mb-4">
          <h2 className="mlm-title">Unlock Your Potential with Our Earners Program</h2>
          <p className="mlm-description">Shop, Sell, Succeed: Turn Every Purchase into Profit</p>
          <Row>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-users"></i>
              <h4>Build Your Team</h4>
              <p>Invite friends and family to join, and watch your team grow!</p>
            </Col>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-coins"></i>
              <h4>Earn Commission</h4>
              <p>Receive commissions for every sale made by your referrals.</p>
            </Col>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-trophy"></i>
              <h4>Achieve Rewards</h4>
              <p>Unlock exclusive bonuses and rewards as you hit milestones.</p>
            </Col>
          </Row>
          <Button variant="success" className="get-started-button" href='/signup'>Get Started</Button>
        </div>

        {/* Income Structure Section */}
        <div className="income-structure text-center mb-4">
          <h2>Your Income Structure</h2>
          <p>Explore how our structured income program can enhance your earnings!</p>
          <Row>
            <Col md={6} className="income-level">
              <h4>Level 1: Direct Referrals</h4>
              <p>Earn a bonus for each direct referral.</p>
            </Col>
            <Col md={6} className="income-level">
              <h4>Level 2: Team Earnings</h4>
              <p>Receive a percentage from team members' sales.</p>
            </Col>
          </Row>
          <Button variant="info" className="learn-more-button" href='/signup'>Learn More</Button>
        </div>

        {/* Testimonials and Achievements */}
        <Testimonials />
        <Achievements />
      </Container>
      <Footer />
    </>
  );
}

export default Dashboard;