import React from 'react';
import Approute from './routes/approutes';

function App() {
  return (
    <div className="App">
      <Approute/>
    </div>
  );
}

export default App;
