import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../navbar/header';
import './membersTree.css';

const MembersData = () => {
    const [members, setMembers] = useState(null); // State to store the root member and their downline
    const [memberStack, setMemberStack] = useState([]); // Stack to manage member navigation
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [token, setToken] = useState(null); // State to store the token

    useEffect(() => {
        const retrievedToken = localStorage.getItem('token'); // Adjust if token is stored differently
        setToken(retrievedToken); // Set the token state
    }, []);

    useEffect(() => {
        // Fetch members data from the API using Axios
        const fetchMembers = async () => {
            if (!token) {
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_PROTOCOL}/api/user/downline-members`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                const data = response.data.data;
                setMembers(data); // Set the root member (the logged-in user and their downline)
                setMemberStack([data]); // Initialize the memberStack with the root member
                setLoading(false); // Set loading to false after data fetch
            } catch (error) {
                console.error('Error fetching members:', error.response ? error.response.data.message : error.message);
                setLoading(false);
            }
        };

        fetchMembers(); // Call the fetch function
    }, [token]);

    const selectedMember = memberStack[memberStack.length - 1]; // Current selected member

    const handleClick = (member) => {
        setMemberStack((prevStack) => [...prevStack, member]); // Add the clicked member to the stack
    };

    const handleBack = () => {
        if (memberStack.length > 1) {
            setMemberStack((prevStack) => prevStack.slice(0, -1)); // Go back to the previous member
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedMember]);

    const renderDownlineMembers = (member) => {
        if (!member || !member.children) return null;

        return (
            <ul className="children-list">
                {member.children.map((child) => (
                    <li key={child.id}>
                        <a href="#" onClick={() => handleClick(child)}>
                            <i className="fa fa-user" aria-hidden="true"></i>
                            <span>
                                {child.first_name.slice(0, 7)}
                                <small> ({countDownlineMembers(child)})</small>
                            </span>
                        </a>
                        {child.children && child.children.length > 0 && (
                            <ul className="sub-children-list">
                                {child.children.map((subChild) => (
                                    <li key={subChild.id}>
                                        <a href="#" onClick={() => handleClick(subChild)}>
                                            <i className="fa fa-user" aria-hidden="true"></i>
                                            <span>
                                                {subChild.first_name.slice(0, 7)}
                                                <small> ({countDownlineMembers(subChild)})</small>
                                            </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const countDownlineMembers = (member) => {
        if (!member || !member.children) return 0;
        let count = member.children.length;
        member.children.forEach(child => {
            count += countDownlineMembers(child);
        });
        return count;
    };

    const totalDownline = selectedMember ? countDownlineMembers(selectedMember) : 0;

    return (
        <>
            <Header />
            <div className="tree-container">
                <div className="tree">
                    {loading ? (
                        <p>Loading members...</p>
                    ) : (
                        <>
                            {memberStack.length > 1 && (
                                <button onClick={handleBack} className="back-button">← Back</button>
                            )}
                            <ul>
                                <li>
                                    {selectedMember ? (
                                        <a href="#">
                                            <i className="fa fa-user" aria-hidden="true"></i>
                                            <span>
                                                {selectedMember.first_name}
                                                <small> ({totalDownline})</small>
                                            </span>
                                        </a>
                                    ) : (
                                        <p>You don't have any members. Add members using a reference link.</p>
                                    )}
                                    {renderDownlineMembers(selectedMember)}
                                </li>
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MembersData;
