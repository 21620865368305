import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Button, Carousel } from 'react-bootstrap';
import './product.css';

function ProductDetails() {
  const [showDescription, setShowDescription] = useState(false);
  const navigate = useNavigate();

  const product = {
    id: 1,
    name: 'Key Soul Infinity Perfume',
    price: 1599,
    discount: 100,
    finalPrice: 1499,
    imageUrl: './rcm_vyomini_jute_bad.jpg',
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleBuyNow = () => {
    navigate('/Buying-Product', { state: { product } });
  };

  return (
    <Container className="unique-product-container">
      <h1 className="unique-product-title">Product Details</h1>
      <Card key={product.id} className="unique-product-card">
        <Card.Body className="unique-product-body">
          <div className="unique-product-image-wrapper">
            <img src={product.imageUrl} className="unique-product-image" alt="product" />
          </div>
          <div className="unique-product-content">
            <h6 className="unique-product-name">{product.name}</h6>
            <h2 className="unique-product-price">
              ₹{product.finalPrice}{' '}
              <span className="unique-product-original-price">₹{product.price}</span>
            </h2>
            <Button className="unique-buy-button" onClick={handleBuyNow}>
              Buy Now
            </Button>
            <h4 className="unique-product-details-heading">Product Details</h4>
            <div
              onClick={toggleDescription}
              className="unique-product-description-toggle"
            >
              DESCRIPTION
              <p className="unique-product-description-text">
                Time to reveal the authentic charm! Now, you can unleash the real softness
                and beauty of your skin...
                <span className="unique-product-click-here">Click here</span>
              </p>
            </div>

            {showDescription && (
              <div className="unique-product-description-content">
                <p>
                  <h4>Face Scrub</h4>
                  <strong>Key Features:</strong>
                  <p>Scrubs away dirt and dullness due to pollution from the skin
                  Rich in antioxidants which protect the skin cells from radical damage
                  Offers extra care and helps to prevent premature aging of skin
                  Exfoliates dead skin
                  Keeps skin pores clean for better perspiration of the cells to release toxins
                  Carries an exotic fragrance.</p>
                  <br />
                 <h4>Bath Soap</h4>
                 <strong>Key Features:</strong>
                  <p>Experience authenticity of organic Honey, Milk, and Moringa extracts
                  Provides relishing freshness with every bath
                  Purifies, nourishes, and deeply cleanses your skin
                  Anti-oxidant properties reduce the rashes and dryness of your skin
                  Pleasant fragrance and freshness to keep you fresh all day long.</p>
                  <h4>Body Lotion</h4>
                  <strong>Key Features:</strong>
                  <p>Enhance the softness and suppleness of your skin with this lightweight yet incredibly effective moringa lotion. It's skin healthy nutrients such as Vitamin A and Vitamin C will leave your skin looking radiant, smooth, and silky to touch.</p>
                  <h4>Hair Oil</h4>
                  <strong>Key Features:</strong>
                   <p>Regain fuller and denser hair. Stimulates hair papilla cells and reduces hair loss. RootBioTec™ proved in a clinical study to significantly reduce hair loss by 31% after just two months of application. Richness of Sunflower, Rice bran, Vitamin E, and Moringa oil maintain scalp health and fortify hair follicles by deep conditioning. Free from Parabens, mineral oil, and dyes.</p>
                   <h4>Moringa Cream</h4>
                   <strong>Key Features:</strong>
                   <p>Time to reveal the authentic charm! Now, you can unleash real softness and beauty of your skin.</p>
                </p>
              </div>
            )}
            <div> 
              <br/>
            <h3 className='kitproducts'>The kit contains the following products </h3>          {/* Carousel with 5 images */}
            <Carousel className="unique-product-carousel">
              <Carousel.Item>
                <img
                  alt='scrub'
                  className="d-block w-100"
                  src="./moringa_face_scrub.jpg"
                 
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                alt='hair oil'
                  className="d-block w-100"
                  src="./moringa_hair_oil.jpg"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                alt='soap'
                  className="d-block w-100"
                  src="./moringa_soap.jpg"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                alt='cream'
                  className="d-block w-100"
                  src="./moringa_cream.jpg"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  alt='body lotion'
                  className="d-block w-100"
                  src="./moringa_body_lotion.jpg"
                />
              </Carousel.Item>
            </Carousel>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ProductDetails;